<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          v-if="isProfileExists"
          title="Profil mahasiswa"
          icon="account"
          class="tile is-child"
        >
          <!-- <user-avatar
            :avatar="form.avatar"
            class="image has-max-width is-aligned-center"
          /> -->
          <!-- <hr /> -->
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly />
          </b-field>
          <b-field label="NIM">
            <b-input :value="form.noid" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Email">
            <b-input :value="form.email" custom-class="is-static" readonly />
          </b-field>
          <!-- <b-field label="Kontak">
            <b-input :value="form.phone" custom-class="is-static" readonly />
          </b-field> -->
          <b-field label="Konsentrasi">
            <b-input :value="form.kbk" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Kelas">
            <b-input :value="form.kelas" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Jenjang">
            <b-input :value="form.jenjang" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Created">
            <b-input
              :value="form.created_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <hr />
        </card-component>
        <card-component
          v-if="isProfileExists"
          title="Informasi Proposal Dan Pembayaran"
          icon="account"
          class="tile is-child"
        >
          <!-- <user-avatar
            :avatar="form.avatar"
            class="image has-max-width is-aligned-center"
          /> -->
          <!-- <hr /> -->
          <!-- <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly />
          </b-field>
          <b-field label="NIM">
            <b-input :value="form.noid" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Email">
            <b-input :value="form.email" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Konsentrasi">
            <b-input :value="form.kbk" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Kelas">
            <b-input :value="form.kelas" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Jenjang">
            <b-input :value="form.jenjang" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Created">
            <b-input
              :value="form.created_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <hr /> -->
          <div v-for="p of form.proposals" v-bind:key="p.id">
            <b-field label="Judul Proposal">
                {{ p.judul }}
            </b-field>
            <b-field label="Link KHS">
                <a :href="form.payments[0].khs" target="_blank"> {{ form.payments[0].khs}}</a>
            </b-field>
            <b-field label="Link Proposal">
                <a :href="p.file" target="_blank"> {{ p.file}}</a>
            </b-field>
            <b-field label="Link STUG">
                <a :href="p.stug" target="_blank"> {{ p.stug}}</a>
            </b-field>
            <b-field label="status">
                {{ p.status }}
            </b-field>
            <hr>
          </div>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'Form',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'Tambah Mahasiswa'
      }

      return ['Dashboard', 'Mahasiswa', lastCrumb]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Tambah Mahasiswa'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'mahasiswa.create' }
      } else {
        return '/'
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'Tambah Mahasiswa'
      } else {
        return 'Dashboard'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Mahasiswa'
      } else {
        return 'Tambah Mahasiswa'
      }
    }
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false

      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  mounted () {
  },
  created () {
    this.getData()
  },
  methods: {
    getClearFormObject () {
      return {
        noid: null,
        name: null,
        email: null,
        phone: null
      }
    },
    getData () {
      if (this.$route.params.id) {
        const id = parseInt(this.$route.params.id)
        axios
          .get(`/mahasiswa/${id}`)
          .then((r) => {
            console.log(r.data)
            if (r.data.status === 'ok') {
              this.form = r.data.data
              this.form.kbk_id = parseInt(this.form.kbk_id)
              this.isProfileExists = true
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              queue: false
            })
          })
      }
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
      if (r.data.status === 'ok') {
        this.$router.push({ name: 'mahasiswa' })
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submit () {
      const data = this.form
      data.password = data.noid

      this.isLoading = true

      if (this.$route.params.id) {
        axios
          .put(`/mahasiswa/${this.$route.params.id}`, data)
          .then(this.handleResponse)
          .catch(this.handleError)
      } else {
        axios
          .post('/mahasiswa', data)
          .then(this.handleResponse)
          .catch(this.handleError)
      }
    }
  }
}
</script>
